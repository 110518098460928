import React, { useEffect } from 'react';
import Footer from '../Components/Footer/Footer'
import Header from '../Components/Header/Header'
import PrivacyPolicyComp from '../Components/PrivacyPolicy/PrivacyPolicyComp'

const PrivacyPolicy = () => {
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 100);
  }, []);
  return (
    <>
    <Header/>

    <PrivacyPolicyComp/>

    <Footer/>
    </>
  )
}

export default PrivacyPolicy